
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import classNames from "classnames";
import { NextPage } from "next";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useTimeoutFn } from "react-use";
import { Button } from "~/components/ui/Button";
import { useWelcomeDict } from "~/hooks/useTranslations";
import Logo from "~/public/images/logo-horizontal.svg";
import WelcomeBackground from "~/public/images/arena-new.png";
import { getLogInUrl, getSignUpUrl } from "~/utils/url";

const WelcomePage: NextPage = () => {
  const { t, tc } = useWelcomeDict();
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [returnTo, setReturnTo] = useState("");

  useEffect(() => {
    const { searchParams } = new URL(document.location.href);
    setReturnTo(searchParams.get("returnTo") ?? "");
  }, [setReturnTo]);

  useTimeoutFn(() => {
    setIsPanelVisible(true);
  }, 1000);

  const clxBg = classNames(
    "relative",
    "w-full",
    "h-full",
    "animate-in",
    "fade-in",
    "duration-700",
    "transition-transform",
    {
      "-translate-y-32": isPanelVisible,
    },
  );

  const clxLoginPanel = classNames(
    "duration-700",
    "transition-transform",
    "absolute",
    "z-10",
    "bottom-[-359px]",
    "w-full",
    "h-[359px]",
    "mt-auto",
    "p-4",
    "pt-9",
    "pb-14",
    "bg-white",
    "rounded-t-3xl",
    "flex",
    "flex-col",
    "justify-center",
    "items-center",
    {
      "-translate-y-[359px]": isPanelVisible,
    },
  );

  return (
    <>
      <div className={clxBg}>
        <Image
          src={WelcomeBackground}
          layout="fill"
          objectFit="cover"
          alt="Background image"
          priority
        />
      </div>

      <div className={clxLoginPanel}>
        <div style={{ width: 143, height: 35 }}>
          <Logo />
        </div>

        <p className="mb-8 mt-4 text-body-3">{t("fpWelcomeTitle")}</p>

        <Link href={getSignUpUrl()}>
          <a>
            <Button
              data-testid="signup-button"
              size="lg"
              className="w-full flex-auto"
            >
              {t("fpSignUp")}
            </Button>
          </a>
        </Link>

        <p className="mt-4 text-center text-caption-1">
          {t("fpAlreadyHaveAnAccount")}{" "}
          <Link href={getLogInUrl(returnTo)}>
            <a>
              <Button
                variant="ghost"
                className="flex-auto"
                data-testid="login-button"
              >
                {tc("fpLogIn")}
              </Button>
            </a>
          </Link>
        </p>
      </div>
    </>
  );
};

export default WelcomePage;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/welcome/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  